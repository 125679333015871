<template>
  <!-- 销售管理详情  采购管理详情 -->
  <div class="purchasing-details-page-box page-info-content">
    <h4 class="page-title">
      {{ pageTitle }}
    </h4>
    <!-- 商品明细 -->
    <div class="commodity-details-box">
      <!-- <h4 class="subtitle">
        商品明细
      </h4> -->
      <p v-if="$route.path === '/transactionData/marketDetails'" class="contract-number">
        关联采购合同编号：{{ detailsObj.relationPurchanseContractCode }}
      </p>
      <div class="statistics-box border">
        <span>签订日期</span>
        <p>{{ detailsObj.signDate }}</p>
        <span>签订地点</span>
        <p>{{ detailsObj.provinceName }}{{ detailsObj.cityName }}</p>
        <span>合同编号</span>
        <el-tooltip
          class="item"
          effect="dark"
          :content="detailsObj.contractCode"
          placement="top"
        >
          <p>
            <i class="elp-1">{{ detailsObj.contractCode }}</i>
          </p>
        </el-tooltip>
        <span>税率(%)</span>
        <p>{{ detailsObj.taxRate }}</p>
      </div>
      <div class="statistics-box no-border">
        <span>采购方</span>
        <el-tooltip
          class="item"
          effect="dark"
          :content="detailsObj.purchaseCmpName"
          placement="top"
        >
          <p>
            <i class="elp-1">{{ detailsObj.purchaseCmpName }}</i>
          </p>
        </el-tooltip>
        <span>销售方</span>
        <el-tooltip
          class="item"
          effect="dark"
          :content="detailsObj.saleCmpName"
          placement="top"
        >
          <p class="elp-1">
            <i class="elp-1">{{ detailsObj.saleCmpName }}</i>
          </p>
        </el-tooltip>
        <span>交(提)货截止</span>
        <p>{{ detailsObj.deliveryExpirationDate }}</p>
        <span>提货仓库</span>
        <p>{{ detailsObj.deliveryWarehouse }}</p>
      </div>
      <div class="statistics-box">
        <span>数量合计</span>
        <p>{{ detailsObj.totalQuantity }}</p>
        <span>金额合计</span>
        <p>{{ detailsObj.totalAmount }}</p>
      </div>
      <div class="statistics-box">
        <span>金额大写</span>
        <p>{{ detailsObj.totalAmountUppercase }}</p>
        <span>备注</span>
        <p>{{ detailsObj.remark }}</p>
      </div>
    </div>
    <!-- 材料清单 -->
    <div class="commodity-details-box">
      <h4 class="subtitle">
        货物明细
      </h4>
      <Table :item-data="itemData" :list-data="listdata" />
    </div>
    <!-- 材料清单 -->
    <div class="commodity-details-box">
      <h4 class="subtitle">
        材料清单
      </h4>
      <Table :item-data="billMaterialsItemArr" :list-data="uploadData">
        <template #operation="{ row }">
          <div v-if="row.materialUrl" class="havenUploaded">
            <img
              v-if="row.type === 'pdf'"
              src="@/assets/image/fileType1.png"
              alt=""
            >
            <img v-else :src="$fileUrl + row.materialUrl" alt="">
            <span class="name elp-1">{{ row.fileName }}</span>
            <!-- 除了图片之外都不可以查看了 -->
            <a :href="$fileUrl + row.materialUrl" target="_blank">
              <el-button link type="text" size="small"> 查看 </el-button>
            </a>
            <el-button
              link
              type="text"
              size="small"
              @click.prevent="downloadAllFile(row)"
            >
              下载
            </el-button>
          </div>
        </template>
      </Table>
    </div>
    <!-- 提货记录 -->
    <div class="commodity-details-box">
      <h4 class="subtitle">
        提货记录
      </h4>
      <Table :item-data="deliveryRecordItemArr" :list-data="detailsObj.detailGoodsDetailList||[]" />
      <div class="button-assembly-box">
        <el-button size="small" @click="back">
          返回
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table'
import { detailExchangeInfo } from '@/api/transactionData'
import { downloadFile2, thousandsFractionsSmaller } from '@/utils/util'
export default {
  components: { Table },
  data() {
    return {
      deliveryRecordItemArr: [
        { label: '货物名称', prop: 'deliveryGoodsName' },
        { label: '品牌', prop: 'deliveryGoodsBrand' },
        { label: '型号规格', prop: 'deliveryGoodsMaterial' },
        { label: '单位', prop: 'deliveryGoodsUnit', child: this.$store.getters.getDictionaryItem('purchaseUnit') },
        { label: '合同数量', prop: 'contractQuantity' },
        { label: '提单编号', prop: 'deliveryCode' },
        { label: '提货日期', prop: 'deliveryDate' }
      ],
      billMaterialsItemArr: [
        { label: '序号', prop: 'index', width: '80' },
        { label: '材料名称', prop: 'documentName', width: '200' },
        { label: '操作', prop: 'operation', width: '800' }
      ],
      itemData: [
        { label: '品种', prop: 'firstGoodsName' },
        { label: '货物名称', prop: 'goodsName' },
        { label: '品牌', prop: 'goodsBrand' },
        { label: '型号规格', prop: 'goodsMaterial' },
        { label: '单位', prop: 'goodsUnit', child: this.$store.getters.getDictionaryItem('purchaseUnit') },
        { label: '数量', prop: 'goodsQuantity' },
        { label: '含税单价(元)', prop: 'taxUnitPrice' },
        { label: '税额(元)', prop: 'taxAmount' },
        { label: '不含税金额(元)', prop: 'excludingTaxTotalAmount' },
        { label: '含税金额(元)', prop: 'includingTaxTotalAmount' }
      ],
      listdata: [],
      detailsObj: {},
      pageTitle: '采购订单',
      uploadData: [
        { materialType: '01', index: 1, documentName: '购销合同' },
        { materialType: '02', index: 2, documentName: '提货单' },
        { materialType: '03', index: 3, documentName: '点价确认单' },
        { materialType: '04', index: 4, documentName: '发票' },
        { materialType: '05', index: 5, documentName: '付款凭证' }
      ]
    }
  },
  created() {
    this.init()
    if (this.$route.path === '/transactionData/marketDetails') {
      this.uploadData[4].documentName = '收款凭证'
      this.pageTitle = '销售订单'
    }
  },
  methods: {
    init() {
      if (!this.$route.query.id) return
      detailExchangeInfo(this.$route.query.id, res => {
        // 合计金额
        res.data.totalAmount = thousandsFractionsSmaller(res.data.totalAmount || 0)
        this.detailsObj = { ...res.data }
        this.listdata = [...this.detailsObj.goodsDetailList]
        // 商品列表
        this.listdata.forEach(item => {
          item.taxUnitPrice = thousandsFractionsSmaller(item.taxUnitPrice)
          item.taxAmount = thousandsFractionsSmaller(item.taxAmount)
          item.excludingTaxTotalAmount = thousandsFractionsSmaller(item.excludingTaxTotalAmount)
          item.includingTaxTotalAmount = thousandsFractionsSmaller(item.includingTaxTotalAmount)
        })
        // 资料明细
        this.uploadData.forEach(item => {
          this.detailsObj.materialBillList.forEach(val => {
            if (val.materialType === item.materialType) {
              this.$set(item, 'fileName', val.fileName)
              this.$set(item, 'materialUrl', val.materialUrl)
              if (val.materialUrl) {
                const type = val.materialUrl.split('.')
                this.$set(item, 'type', type.length >= 2 ? type[1] : '')
              }
            }
          })
        })
      })
    },
    // 取消按钮
    back() {
      this.$router.go(-1)
    },
    // 点击下载文件按钮
    downloadAllFile(row) {
      // 传入URL 名字 类型
      downloadFile2(this.$fileUrl + row.materialUrl, row.fileName, row.type)
    }
  }
}
</script>

<style lang="scss" scoped>
.purchasing-details-page-box {
  background-color: #fff;

  .commodity-details-box {
    display: flex;
    justify-content: center;
    flex-direction: column;
    .contract-number{
      padding-bottom: 12px;
      font-size: 14px;
      text-align: right;
    }
    .button-assembly-box {
      text-align: center;
      padding: 24px 0;
    }
    .statistics-box {
      display: flex;
      align-items: center;
      color: #333;
      font-size: 14px;
      border: 1px solid #ededed;
      border-top: 0;
      .el-input {
        /deep/.el-input__inner {
          border: 0;
        }
      }

      span {
        flex: 0 0 156px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        border-right: 1px solid #ededed;
        color: #999;
      }
      p {
        display: flex;
        align-items: center;
        flex: 1;
        height: 42px;
        padding: 0 8px;
        border-right: 1px solid #ededed;
        i{
          font-style: normal;
        }
      }
    }
    .border{
        border-top: 1px solid #ededed;
    }
    .subtitle {
      padding: 24px 0;
    }
    .delete-icon {
      font-size: 28px;
      color: $pointClearColor;
      cursor: pointer;
      margin-left: 8px;
    }
    .add-icon {
      font-size: 28px;
      color: $dominantColor;
      cursor: pointer;
    }
  }
  .havenUploaded {
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/.el-upload {
      display: flex;
      align-items: center;
    }
    .name {
      font-size: 14px;
      color: #333;
      margin-right: 12px;
      width: 320px;
    }
    img {
      height: 24px;
      width: 24px;
      margin-right: 12px;
    }
    .el-button {
      margin-right: 12px;
    }
    a {
      margin-right: 12px;
    }
  }
  .page-title {
    text-align: center;
    font-size: 20px;
    padding: 12px 0 24px;
    font-weight: 500;
  }
  .el-form {
    padding: 0 42px;
    /deep/.el-form-item {
      .el-form-item__content {
        width: auto;
      }
    }
  }
}
</style>
